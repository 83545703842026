import * as React from 'react'
/*
 * APIコール時等でパラメータ不正がある場合に遷移する画面でレンダリングするコンポーネントです。
 */
export default function InvalidUrl(): React.FunctionComponentElement<{}> {
  return (
    <article className="container">
      <div className="content">
        <section id="page3">
          <div className="container">
            <div className="title">
              <h2>URLが不正です</h2>
            </div>
            <div className="col-xs-12">
              アクセスされたURLが間違っている可能性があります。
              <br />
              再度最初からお試しいただくか、URLをご確認ください。
            </div>
          </div>
        </section>
      </div>
    </article>
  )
}
