import * as React from 'react'
interface CampaignCodeErrorTextProps {
  campaignCodeError: boolean | undefined
}
/*
 * ページロードアイコンをレンダリングするコンポーネントです。
 */
const CampaignCodeErrorText: React.FC<CampaignCodeErrorTextProps> = (
  props: CampaignCodeErrorTextProps
): React.ReactElement | null => {
  if (!props.campaignCodeError) {
    return null
  }
  return (
    <div className="campaign-code-error">
      <div>キャンペーンコードが</div>
      <div>認証できませんでした。</div>
    </div>
  )
}

export default CampaignCodeErrorText
