import * as React from 'react'
import CampaignTerm from 'src/components/layouts/campaignTerm'
import SitePolicy from 'src/components/layouts/sitePolicy'
import CampaignAbout from 'src/components/layouts/campaignAbout'

interface Page3SectionProps {
  campaignInfo?: CampaignInfo
}

interface CampaignInfo {
  startAt?: number
  endAt?: number
  termView: boolean
  sitePolicy?: string
  contactInformation?: string
  campaignAbout?: string
}

/*
 * キャンペーンページのPage3セクション（「キャンペーン概要」「応募期間」「応募規約」）をレンダリングするコンポーネントです。
 */
const Page3Section: React.FC<Page3SectionProps> = (
  props: Page3SectionProps
): React.ReactElement | null => {
  if (!props.campaignInfo) {
    return null
  } else {
    return (
      <section id="page3">
        <CampaignAbout campaignAbout={props.campaignInfo.campaignAbout} />
        <CampaignTerm
          startAt={props.campaignInfo.startAt}
          endAt={props.campaignInfo.endAt}
          termView={props.campaignInfo.termView}
        />
        <SitePolicy sitePolicy={props.campaignInfo.sitePolicy} />
      </section>
    )
  }
}

export default Page3Section
