import * as React from 'react'
import ActionDispatcher from 'src/containers/actionDispatcher'
import PageLoadingIcon from 'src/components/ui/pageLoadingIcon'
import liff from '@line/liff'
import { CampaignState } from 'src/modules/campaign'
import InvalidUrl from './invalidUrl'
import { bugsnagClient } from 'src/bugsnag'

interface Props {
  actions: ActionDispatcher
  campaign: CampaignState
}

interface LocalState {
  isValidLiffStateParams: boolean
}
/*
 * LINEログインでcallbackのURLからリダイレクトされた際にレンダリングされるコンポーネントです。
 * URLフラグメントにid_tokenが付与されています。
 */
export class LiffAuth extends React.Component<Props, LocalState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isValidLiffStateParams: false
    }
  }
  async componentDidMount(): Promise<void> {
    const params = new URLSearchParams(window.location.search)
    const state = params.get('liff.state')
    if (state) {
      const extWebCampaignKey = state.replace(/\//g, '')
      await this.props.actions.asyncGetCampaign(extWebCampaignKey)
    } else {
      return this.setState({ isValidLiffStateParams: true })
    }
    if (
      !window.document.querySelector(
        'script[src^="https://static.line-scdn.net"]'
      )
    ) {
      const liffId = this.props.campaign.campaignInfo?.liffId
      await liff
        .init({
          liffId: `${liffId ? liffId : process.env.REACT_APP_LIFF_ID}`,
          mock: false
        })
        .catch((e): void => {
          alert(`LIFF error: ${e.message}`)
          bugsnagClient.notify(e)
        })
    }
  }

  render(): React.ReactElement {
    if (this.state.isValidLiffStateParams) {
      return <InvalidUrl />
    } else {
      return <PageLoadingIcon />
    }
  }
}
