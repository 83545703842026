import * as React from 'react'
import { Route, Switch } from 'react-router' // react-router v4/v5
import TopPage from 'src/containers/toppage'
import Lottery from 'src/containers/lottery'
import TwitterAuth from 'src/containers/twitterAuth'
import LiffApp from 'src/containers/liffApp'
import NotFound from 'src/components/pages/notFound'
import RoutingFailure from 'src/components/pages/routingFailure'
import InvalidUrl from 'src/components/pages/invalidUrl'
import InvalidToken from 'src/components/pages/invalidToken'
import InvalidSession from 'src/components/pages/invalidSession'
import CampaignPeriod from 'src/components/pages/campaignPeriod'
import AppError from 'src/components/pages/appError'
import LiffAuth from 'src/containers/liffAuth'

export class Routes extends React.Component<{}, {}> {
  render(): React.ReactElement {
    return (
      <div>
        <Switch>
          <Route
            exact
            path="/:campaignKey/twitter_auth"
            component={TwitterAuth}
          />
          <Route
            exact
            path="/:campaignKey/twitter_auth/lottery"
            component={Lottery}
          />
          <Route
            exact
            path="/:campaignKey/:campaignCode/lottery"
            component={Lottery}
          />
          <Route exact path="/rate_limit" component={AppError} />
          <Route exact path="/routing_failure" component={RoutingFailure} />
          <Route exact path="/invalid_url" component={InvalidUrl} />
          <Route exact path="/invalid_token" component={InvalidToken} />
          <Route exact path="/invalid_session" component={InvalidSession} />
          <Route exact path="/campaign_period" component={CampaignPeriod} />
          <Route exact path="/app_error" component={AppError} />
          <Route exact path="/liff_app" component={LiffAuth} />
          <Route exact path="/liff_app/:campaignKey" component={LiffApp} />
          <Route exact path="/:campaignKey" component={TopPage} />
          <Route exact path="/:campaignKey/:campaignCode" component={TopPage} />
          <Route component={NotFound} />
        </Switch>
      </div>
    )
  }
}
