import { Lottery } from 'src/components/pages/lottery'
import { TopPageState } from 'src/modules/toppage'
import { CampaignState } from 'src/modules/campaign'
import {
  connect,
  MapDispatchToPropsParam,
  MapStateToPropsParam
} from 'react-redux'
import { LotteryState } from 'src/modules/lottery'
import ActionDispatcher from 'src/containers/actionDispatcher'
import { RouteComponentProps } from 'react-router'
import { ReduxAction, ReduxState } from 'src/store'
import { Dispatch } from 'redux'
import { RouterState } from 'connected-react-router'

type RouterProps = RouteComponentProps<{
  campaignKey: string | undefined
  campaignCode: string | undefined
}>

type StateProps = {
  value: TopPageState
  lottery: LotteryState
  campaign: CampaignState
  router: RouterState
  campaignKey: string | undefined
  campaignCode: string | undefined
}

const mapStateToProps: MapStateToPropsParam<{}, RouterProps, ReduxState> = (
  state: ReduxState,
  ownProps: RouterProps
): StateProps => {
  return {
    value: state.toppage,
    lottery: state.lottery,
    campaign: state.campaign,
    router: state.router,
    campaignKey: ownProps.match.params.campaignKey,
    campaignCode: ownProps.match.params.campaignCode
  }
}

const mapDispatchToProps: MapDispatchToPropsParam<
  { actions: ActionDispatcher },
  {}
> = (dispatch: Dispatch<ReduxAction>): { actions: ActionDispatcher } => ({
  actions: new ActionDispatcher(dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Lottery)
