import toppage, { TopPageActions, TopPageState } from './modules/toppage'
import lottery, { LotteryActions, LotteryState } from './modules/lottery'
import campaign, { CampaignActions, CampaignState } from './modules/campaign'

import { createStore, combineReducers, Action, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import { createBrowserHistory } from 'history'
import {
  RouterState,
  routerMiddleware,
  connectRouter,
  RouterAction
} from 'connected-react-router'
import snsAuth, { SnsAuthActions, SnsAuthState } from './modules/snsAuth'
export const history = createBrowserHistory()
const middlewares = [routerMiddleware(history)]
if (process.env.REACT_APP_NODE_ENV !== `production`) {
  const logger = createLogger({
    diff: true,
    collapsed: true
  })
  middlewares.push(logger)
}

const rootReducer = combineReducers({
  router: connectRouter(history),
  toppage,
  lottery,
  campaign,
  snsAuth
})

// 永続化設定されたReducerとして定義
const store = createStore(rootReducer, applyMiddleware(...middlewares))

//export const persistor = persistStore(store);

export default store

export type ReduxState = {
  router: RouterState
  toppage: TopPageState
  lottery: LotteryState
  campaign: CampaignState
  snsAuth: SnsAuthState
}

export type ReduxAction =
  | TopPageActions
  | LotteryActions
  | CampaignActions
  | SnsAuthActions
  | Action
  | RouterAction
