import * as React from 'react'
import ActionDispatcher from 'src/containers/actionDispatcher'
import PageLoadingIcon from 'src/components/ui/pageLoadingIcon'
import { CampaignState } from 'src/modules/campaign'
import InvalidUrl from 'src/components/pages/invalidUrl'
import { TopPageState } from 'src/modules/toppage'
import CampaignBefore from 'src/components//layouts/campaignBefore'
import Validation from 'src/validate'
import PageHeader from 'src/components//layouts/pageHeader'
import HeaderErrorText from 'src/components//ui/headerErrorText'
import Header from 'src/components//layouts/header'
import Page1Section from 'src/components//layouts/page1Section'
import Page2Section from 'src/components//layouts/page2Section'
import Page3Section from 'src/components//layouts/page3Section'
import Footer from 'src/components//layouts/footer'
import LotteryGimmick from 'src/components//layouts/lotteryGimmick'
import CampaignTermEnd from 'src/components//layouts/campaignTermEnd'
import LiffAppLoginButton from 'src/components/ui/liffAppLoginButton'
import liff from '@line/liff'
import NotLineFriendshipErrorText from 'src/components//ui/isNotLineFriendshipText'
import LiffLotteryButton from 'src/components//ui/liffLotteryButton'
import { bugsnagClient } from 'src/bugsnag'
import { Redirect } from 'react-router'
interface Props {
  value: TopPageState
  actions: ActionDispatcher
  campaignKey: string
  campaign: CampaignState
  location?: RouterLocationState
}

interface RouterLocationState {
  state?: { [key: string]: string }
}

interface ButtonTextHash {
  loadingText: string
  lotteryText: string
}

interface LocalState {
  isLiffInitialized: boolean
  isNotLineFriendship: boolean
}
/*
 * LINEログインでcallbackのURLからリダイレクトされた際にレンダリングされるコンポーネントです。
 * URLフラグメントにid_tokenが付与されています。
 */
export class LiffApp extends React.Component<Props, LocalState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isLiffInitialized: false,
      isNotLineFriendship: false
    }
  }
  async componentDidMount(): Promise<void | string> {
    await this.props.actions.asyncGetCampaign(this.props.campaignKey)
    if (
      !window.document.querySelector(
        'script[src^="https://static.line-scdn.net"]'
      )
    ) {
      const liffId = this.props.campaign.campaignInfo?.liffId
      await liff
        .init({
          liffId: `${liffId ? liffId : process.env.REACT_APP_LIFF_ID}`
        })
        .then((): void => {
          this.setState({ isLiffInitialized: true })
        })
        .catch((e): void => {
          alert(`LIFF error: ${e.message}`)
          bugsnagClient.notify(e)
        })
    } else {
      this.setState({ isLiffInitialized: true })
    }
  }

  render(): React.ReactElement {
    // キャンペーン情報を取得中の場合
    if (this.props.value.loadingGetCampaignInfoCount !== 0) {
      return <PageLoadingIcon />
    }
    const { campaignInfo } = this.props.campaign
    // キャンペーン情報を取得できなかった場合
    if (!campaignInfo) {
      return <InvalidUrl />
    }
    const {
      campaignTitle,
      campaignLogoImageUrl,
      contactInformation,
      lotteryGimmick
    } = campaignInfo

    if (campaignInfo.lotteryGimmick && this.props.value.loadingCount !== 0) {
      return <LotteryGimmick lotteryGimmick={lotteryGimmick} />
    }
    // キャンペーン期間前の場合
    if (Validation.CampaignStartIsBefore(campaignInfo.startAt)) {
      return <CampaignBefore campaignInfo={campaignInfo} />
    }
    if (campaignInfo.lotteryAuthMethod !== 'LINE') {
      return (
        <Redirect
          to={{
            pathname: `/${this.props.campaignKey}`
          }}
        />
      )
    }
    // それ以外の場合
    return (
      <div>
        <PageHeader campaignTitle={campaignTitle} />
        <HeaderErrorText location={this.props.location} />
        <Header campaignLogoImageUrl={campaignLogoImageUrl} />
        <article className="container">
          <div className="content">
            <Page1Section>{this.renderPage1Contents()}</Page1Section>
          </div>
        </article>
        <article>
          <div className="content">
            <Page2Section
              campaignInfo={campaignInfo}
              webCampaignKey={this.props.campaignKey}
              isValidArchived={Validation.CampaignEndIsAfter(
                campaignInfo.archiveAt
              )}
            />
            <Page3Section campaignInfo={campaignInfo} />
          </div>
        </article>
        <Footer contactInformation={contactInformation} />
      </div>
    )
  }
  // #page1エリアレンダリング
  private renderPage1Contents(): React.ReactElement | undefined {
    if (!this.props.campaign.campaignInfo) {
      return undefined
    }
    const buttonTextHash = {
      duringTerm: {
        loadingText: '抽選中',
        lotteryText: '応募する'
      },
      afterTerm: {
        loadingText: '結果確認中',
        lotteryText: '結果を確認する'
      }
    }
    const {
      endAt,
      campaignBannerImageUrl,
      archiveAt
    } = this.props.campaign.campaignInfo

    if (Validation.CampaignEndIsAfter(endAt) === true) {
      //当選確認期間が過ぎている場合
      if (Validation.CampaignEndIsAfter(archiveAt)) {
        return <CampaignTermEnd />
      }
      // キャンペーン期間後
      return (
        <CampaignTermEnd>
          <div className="button-wrapper">
            <NotLineFriendshipErrorText
              isNotLineFriendship={this.state.isNotLineFriendship}
            />
            <LiffAppLoginButton
              liff={liff}
              isLiffInitialized={this.state.isLiffInitialized}
            >
              {this.renderLotteryButton(buttonTextHash.afterTerm)}
            </LiffAppLoginButton>
          </div>
        </CampaignTermEnd>
      )
    }
    if (Validation.CampaignEndIsAfter(endAt) === false) {
      // キャンペーン期間内
      return (
        <div>
          <img src={campaignBannerImageUrl} alt="top_banner" />
          <div className="button-wrapper">
            <NotLineFriendshipErrorText
              isNotLineFriendship={this.state.isNotLineFriendship}
            />
            <LiffAppLoginButton
              liff={liff}
              isLiffInitialized={this.state.isLiffInitialized}
            >
              {this.renderLotteryButton(buttonTextHash.duringTerm)}
            </LiffAppLoginButton>
          </div>
        </div>
      )
    }
    return <div></div>
  }

  // 「抽選する」ボタンレンダリング
  private renderLotteryButton(buttonText: ButtonTextHash): React.ReactElement {
    const handleLineFriendshipState = (newState: boolean): void => {
      this.setState({ isNotLineFriendship: newState })
    }
    // ローディング中の場合
    if (this.props.value.loadingCount !== 0) {
      // ローディング用文言のボタンを表示する
      return (
        <span className={'button loading'}>
          <span className="spinner-grow"></span>
          {buttonText.loadingText}
        </span>
      )
    }
    //ローディング中ではない場合
    return (
      <LiffLotteryButton
        campaign={this.props.campaign}
        webCampaignKey={this.props.campaignKey}
        onErrorText={handleLineFriendshipState}
        actions={this.props.actions}
        buttonText={buttonText.lotteryText}
      />
    )
  }
}
