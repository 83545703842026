import * as React from 'react'
import ActionDispatcher from 'src/containers/actionDispatcher'
import PageLoadingIcon from 'src/components/ui/pageLoadingIcon'

interface Props {
  actions: ActionDispatcher
  campaignKey?: string
}

/*
 * TwitterログインでcallbackのURLからリダイレクトされた際にレンダリングされるコンポーネントです。
 */
export class TwitterAuth extends React.Component<Props, {}> {
  async componentDidMount(): Promise<void> {
    const { campaignKey: paramsCampaignKey, actions } = this.props
    // URLフラグメントにid_tokenがある場合、抽選する
    await actions.asyncTwitterAuthLottery(paramsCampaignKey)
  }

  render(): React.ReactElement {
    return <PageLoadingIcon />
  }
}
