import * as React from 'react'
import sanitizeHtml from 'sanitize-html'

interface SitePolicyProps {
  sitePolicy: string | undefined
}
/*
 * キャンペーンの「応募規約」をレンダリングするコンポーネントです。
 */
const SitePolicy: React.FC<SitePolicyProps> = (
  props: SitePolicyProps
): React.ReactElement | null => {
  if (!props.sitePolicy) {
    return null
  }
  return (
    <div className="container">
      <div className="title">
        <h2>応募規約</h2>
        <span>TERMS</span>
      </div>
      <div
        className="col-xs-12"
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(props.sitePolicy)
        }}
      ></div>
    </div>
  )
}
export default SitePolicy
