import * as React from 'react'
/*
 * APIコール時にGoogleRecaptureからbot判定された際にレンダリングされるコンポーネントです。
 */
export default function InvalidToken(): React.FunctionComponentElement<{}> {
  return (
    <article className="container">
      <div className="content">
        <section id="page3">
          <div className="container">
            <div className="title">
              <h2>Botによるアクセスと判定されました</h2>
            </div>
            <div className="col-xs-12">
              引き続きキャンペーンに応募いただくには、再度最初からアクセスしていただく必要がございます。
            </div>
          </div>
        </section>
      </div>
    </article>
  )
}
