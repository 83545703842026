import * as React from 'react'
interface HeaderErrorTextProps {
  location?: {
    state?: {
      [key: string]: string | string
    }
  }
}
/*
 * ページロードアイコンをレンダリングするコンポーネントです。
 */
const HeaderErrorText: React.FC<HeaderErrorTextProps> = (
  props: HeaderErrorTextProps
): React.ReactElement | null => {
  if (!props.location || !props.location.state || !props.location.state.error) {
    return null
  }
  return (
    <div className="header-error-text">
      <div>{props.location.state.error}</div>
    </div>
  )
}

export default HeaderErrorText
