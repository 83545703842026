/*
 * ApplicationError用のインターフェースを実装
 * 効果：nameとmessageプロパティがカスタムクラスに確実に定義される
 *
 * ネイティブのErrorは聖域として扱い、決してスローしないこと。
 * 理由：アプリケーションの例外とErrorとの明確な分離
 */
export class ApplicationError extends Error {
  public name = 'ApplicationError'

  public constructor(public message: string) {
    super(message)
  }
  /*
   * toStringのオーバーライドの推奨
   * 理由：このメソッドをオーバーライドしないと、デフォルト実装であるObject#toString()が呼ばれ、[object Object]が出力される
   */
  toString(): string {
    return this.name + ': ' + this.message
  }
}

//APIコール失敗時のエラー
export class ApiCallFailError extends ApplicationError {
  public name = 'ApiCallFailError'
  public status: number
  public code: string

  public constructor(status: number, code: string) {
    super(`api call failed. response status:${status}`)
    this.status = status
    this.code = code
  }
}
