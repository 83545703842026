import 'react-app-polyfill/ie9'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store, { history } from './store'
import { Routes } from './components/router'
import 'bootstrap/dist/css/bootstrap.css'
import './asset/css/style.css'
import { ConnectedRouter } from 'connected-react-router'
import { bugsnagClient } from './bugsnag'
import bugsnagReact from '@bugsnag/plugin-react'
import FallbackComponent from './components/pages/fallBack'

bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin('react')

ReactDOM.render(
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root') as HTMLElement
)
