import * as React from 'react'
import CampaignTerm from './campaignTerm'
/*
 * APIコール時に抽選期間が終了している場合にレンダリングされるコンポーネントです。
 */

interface CampaignBeforeProps {
  campaignInfo?: CampaignInfo
}

interface CampaignInfo {
  startAt?: number
  endAt?: number
  termView: boolean
}

export default function CampaignBefore(
  props: CampaignBeforeProps
): React.FunctionComponentElement<{}> | null {
  if (!props.campaignInfo) {
    return null
  } else {
    return (
      <div>
        <article className="container">
          <div className="content">
            <section id="page3">
              <div className="container">
                <div className="title">
                  <h2>応募期間前です</h2>
                </div>
                <div className="col-xs-13">
                  応募期間になりましたら再度アクセスしてください。
                </div>
              </div>
              <article>
                <CampaignTerm
                  startAt={props.campaignInfo.startAt}
                  endAt={props.campaignInfo.endAt}
                  termView={props.campaignInfo.termView}
                />
              </article>
            </section>
          </div>
        </article>
      </div>
    )
  }
}
