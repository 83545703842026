import * as React from 'react'
import WinResult from 'src/components/layouts/lottery/winResult'
import LoseResult from 'src/components/layouts/lottery/loseResult'
import InvalidUrl from 'src/components/pages/invalidUrl'

interface LotteryResultProps {
  lotteryResult: {
    productNameTag?: string
    productName?: string
    productImageUrl?: string
    giftContent?: string
    notice?: string
    result?: string
    wonPageTitle?: string
    wonPageSubTitle?: string
    lostPageTitle?: string
    lostPageText?: string
    wonPageTitlePcFontSize?: string
    wonPageTitleSpFontSize?: string
    lostPageTitlePcFontSize?: string
    lostPageTitleSpFontSize?: string
  }
}
/*
 * 抽選結果画面の抽選結果をレンダリングするコンポーネントです。
 */
const LotteryResult: React.FC<LotteryResultProps> = (
  props: LotteryResultProps
): React.ReactElement => {
  const result = props.lotteryResult.result
  const ua = window.navigator.userAgent.toLowerCase()
  let device: 'pc' | 'sp' = 'pc'
  if (
    ua.indexOf('iphone') > 0 ||
    ua.indexOf('ipod') > 0 ||
    (ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0)
  ) {
    device = 'sp'
  }
  if (result === 'winning') {
    return <WinResult lotteryResult={props.lotteryResult} device={device} />
  } else if (result === 'losing') {
    return <LoseResult lotteryResult={props.lotteryResult} device={device} />
  } else {
    return <InvalidUrl />
  }
}
export default LotteryResult
