import * as React from 'react'

interface HeaderProps {
  campaignLogoImageUrl: string | undefined
}
/*
 * キャンペーンページのヘッダー（「キャンペーンロゴ画像」）をレンダリングするコンポーネントです。
 */
const Header: React.FC<HeaderProps> = (
  props: HeaderProps
): React.ReactElement | null => {
  if (!props.campaignLogoImageUrl) {
    return null
  }
  return (
    <header>
      <div className="container">
        <img
          src={props.campaignLogoImageUrl}
          className="img-responsive"
          alt="top_logo"
        />
      </div>
    </header>
  )
}

export default Header
