import * as React from 'react'
import sanitizeHtml from 'sanitize-html'

interface CampaignAboutProps {
  campaignAbout: string | undefined
}
/*
 * キャンペーンの「キャンペーン概要」をレンダリングするコンポーネントです。
 */
const CampaignAbout: React.FC<CampaignAboutProps> = (
  props: CampaignAboutProps
): React.ReactElement | null => {
  if (!props.campaignAbout) {
    return null
  }
  return (
    <div className="container">
      <div className="title">
        <h2>キャンペーン概要</h2>
        <span>ABOUT</span>
      </div>
      <div
        className="col-xs-12"
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(props.campaignAbout, {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img'])
          })
        }}
      ></div>
    </div>
  )
}

export default CampaignAbout
