import * as React from 'react'
import WinTtl from 'src/components/layouts/lottery/winTtl'
import WinThum from 'src/components/layouts/lottery/winThum'
import WinEntry from 'src/components/layouts/lottery/winEntry'
import WinNotice from 'src/components/layouts/lottery/winNotice'
import sanitizeHtml from 'sanitize-html'

interface WinResultProps {
  lotteryResult: {
    productNameTag?: string
    productName?: string
    productImageUrl?: string
    giftContent?: string
    notice?: string
    wonPageTitle?: string
    wonPageSubTitle?: string
    wonPageTitlePcFontSize?: string
    wonPageTitleSpFontSize?: string
  }
  device: 'pc' | 'sp'
}
/*
 * 抽選画面の当選結果をレンダリングするコンポーネントです。
 */
const WinResult: React.FC<WinResultProps> = (
  props: WinResultProps
): React.ReactElement => {
  let titleFontSize
  if (props.lotteryResult.wonPageTitleSpFontSize && props.device === 'sp') {
    titleFontSize = props.lotteryResult.wonPageTitleSpFontSize
  } else if (
    props.lotteryResult.wonPageTitlePcFontSize &&
    props.device === 'pc'
  ) {
    titleFontSize = props.lotteryResult.wonPageTitlePcFontSize
  }
  return (
    <div>
      <section id="page2" className="atari">
        <div className="container">
          <div className="ttl">
            <h2>
              {props.lotteryResult.wonPageTitle && (
                <div
                  style={
                    titleFontSize ? { fontSize: `${titleFontSize}px` } : {}
                  }
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(props.lotteryResult.wonPageTitle)
                  }}
                ></div>
              )}
            </h2>
            <p>
              {props.lotteryResult.wonPageSubTitle && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(props.lotteryResult.wonPageSubTitle)
                  }}
                ></div>
              )}
            </p>
          </div>
          <WinTtl productName={props.lotteryResult.productName} />
          <WinThum
            productName={props.lotteryResult.productName}
            productImageUrl={props.lotteryResult.productImageUrl}
          />
          <WinEntry giftContent={props.lotteryResult.giftContent} />
        </div>
      </section>
      <WinNotice notice={props.lotteryResult.notice} />
    </div>
  )
}
export default WinResult
