import * as React from 'react'
import { useEffect, useState } from 'react'
import sanitizeHtml from 'sanitize-html'

interface LineFriendshipEntryFlowProps {
  lineOfficialAccountName?: string
  lineOfficialProfileImageUrl?: string
  lineAddFriendButtonTag?: string
}
/*
 * LINE友達認証の「キャンペーン概要」をレンダリングするコンポーネントです。
 */
const LineFriendshipEntryFlow: React.FC<LineFriendshipEntryFlowProps> = (
  props: LineFriendshipEntryFlowProps
): React.ReactElement => {
  const [isFriend, setIsFriend] = useState(Boolean)

  useEffect((): void => {
    const fetchData = async (): Promise<void> => {
      if (window?.liff) {
        const liff = window.liff
        if (liff.isLoggedIn()) {
          const result = await liff.getFriendship()
          setIsFriend(result.friendFlag)
        }
      }
    }

    fetchData()
  }, [])

  const lineOfficialProfileImageUrl = (
    lineOfficialProfileImageUrl?: string
  ): React.ReactElement => {
    if (lineOfficialProfileImageUrl) {
      return (
        <div>
          <img
            src={props.lineOfficialProfileImageUrl}
            className="line-official-icon"
            alt="line_official_profile_logo"
          />
        </div>
      )
    } else {
      return <></>
    }
  }
  const lineAddFriendButtonTag = (
    lineAddFriendButtonTag?: string
  ): React.ReactElement => {
    if (lineAddFriendButtonTag) {
      if (isFriend) {
        return <div>友だち追加済み</div>
      } else {
        return (
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(lineAddFriendButtonTag, {
                  allowedTags: ['a', 'img'],
                  allowedAttributes: {
                    a: ['href'],
                    img: ['src', 'alt', 'height', 'border']
                  }
                })
              }}
            ></div>
          </div>
        )
      }
    } else {
      return <></>
    }
  }

  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  return (
    <section id="page2">
      <div className="container">
        <div className="title">
          <i className="fa fa-twitter" aria-hidden="true"></i>
          <h2>応募方法</h2>
          <span>FLOW</span>
          <br />
          <br />
          <span>
            「友だち追加」を押すと友だち追加の確認画面が表示されます。
            <br />
            友だち追加が完了すると「応募する」が押下できます
          </span>
        </div>
        <div className="col-xs-12">
          <div className="step1">
            <div className="col-md-12">
              <h3>Step 1</h3>
              <div className="fa-line-friendship">
                {lineOfficialProfileImageUrl(props.lineOfficialProfileImageUrl)}
                <div>
                  {props.lineOfficialAccountName}
                  LINE公式アカウントを友だち追加してください
                </div>
                {lineAddFriendButtonTag(props.lineAddFriendButtonTag)}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12">
          <div className="step3">
            <div className="col-md-12">
              <h3>Step 2</h3>
              <p>応募ボタンから応募する</p>
              <p>
                <small>
                  友だち追加後 「応募する」ボタンを 押してください。
                </small>
              </p>
              <br />
              <span className="button" onClick={scrollToTop}>
                応募する
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LineFriendshipEntryFlow
