import { TwitterAuth } from 'src/components/pages/twitterAuth'
import {
  connect,
  MapDispatchToPropsParam,
  MapStateToPropsParam
} from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { ReduxAction, ReduxState } from 'src/store'
import { Dispatch } from 'redux'
import ActionDispatcher from './actionDispatcher'

type RouterProps = RouteComponentProps<{
  campaignKey: string | undefined
}>

const mapStateToProps: MapStateToPropsParam<{}, RouterProps, ReduxState> = (
  state: ReduxState,
  ownProps: RouterProps
): {
  campaignKey: string | undefined
} => {
  return {
    campaignKey: ownProps.match.params.campaignKey
  }
}

const mapDispatchToProps: MapDispatchToPropsParam<
  { actions: ActionDispatcher },
  {}
> = (dispatch: Dispatch<ReduxAction>): { actions: ActionDispatcher } => ({
  actions: new ActionDispatcher(dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(TwitterAuth)
