import * as React from 'react'
import LineLogoImg from 'src/asset/images/line_logo.png'
import { Liff } from '@line/liff'
interface LiffProviderProps {
  liff: Liff | undefined
  isLiffInitialized: boolean
  children: React.ReactNode
}
/*
 * LINEログインボタンをレンダリングするコンポーネントです。
 */
const LiffAppLoginButton: React.FC<LiffProviderProps> = (props: {
  liff: Liff | undefined
  isLiffInitialized: boolean
  children: React.ReactNode
}): React.ReactElement => {
  const handleClick = (): void => {
    if (!props.liff) {
      return
    }
    props.liff.login({ redirectUri: window.location.href })
  }
  if (!props.isLiffInitialized) {
    // liff.initが終了していない場合は何も描画しない
    return <></>
  }
  if (!props.liff || !props.liff.isLoggedIn()) {
    return (
      <div>
        <p className="description">下記のボタンからLINE連携して下さい</p>
        <div className="line-login" onClick={handleClick}>
          <img className="icon" src={LineLogoImg} alt="line-btn-icon" />
          <span>LINEでログイン</span>
        </div>
      </div>
    )
  } else {
    return <>{props.children}</>
  }
}

export default LiffAppLoginButton
