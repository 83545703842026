// ActionCreator
import { Action } from 'redux'

enum ActionNames {
  SET_CAMPAIGN_INFO = 'campaign/campaign_info'
}

interface SetCampaignInfoAction extends Action {
  type: ActionNames.SET_CAMPAIGN_INFO
  campaignInfo?: { [key: string]: string }
}

export const SetCampaignInfo = (campaignInfo?: {
  [key: string]: string
}): SetCampaignInfoAction => ({
  type: ActionNames.SET_CAMPAIGN_INFO,
  campaignInfo: campaignInfo
})

//reducer

export interface CampaignState {
  campaignInfo:
    | {
        campaignTitle: string
        campaignLogoImageUrl?: string
        campaignBannerImageUrl?: string
        sitePolicy?: string
        contactInformation?: string
        startAt: number
        endAt: number
        lotteryAuthMethod?: string
        campaignAbout?: string
        entryFlow?: string
        archiveAt?: number
        lotteryGimmick?: number
        termView: boolean
        tweetId?: string
        tweetTag?: string
        followTwitterUser?: string
        liffId?: string
        lineFriendship?: boolean
        lineOfficialAccountName?: string
        lineOfficialProfileImageUrl?: string
        lineAddFriendButtonTag?: string
      }
    | undefined
}

export type CampaignActions = SetCampaignInfoAction

const initialState: CampaignState = {
  campaignInfo: {
    campaignTitle: '',
    campaignLogoImageUrl: '',
    campaignBannerImageUrl: '',
    sitePolicy: '',
    contactInformation: '',
    startAt: 0,
    endAt: 0,
    lotteryAuthMethod: '',
    campaignAbout: '',
    entryFlow: '',
    termView: true
  }
}

export default function reducer(
  state: CampaignState = initialState,
  action: CampaignActions
): CampaignState {
  switch (action.type) {
    case ActionNames.SET_CAMPAIGN_INFO: {
      return Object.assign({}, state, { campaignInfo: action.campaignInfo })
    }
    default:
      return state
  }
}
