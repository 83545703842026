import * as React from 'react'

/*
 * Twitterでの応募ボタンをレンダリングするコンポーネントです。
 */

interface Props {
  webCampaignKey: string
  buttonTextLottery: string
}

const TwitterLotteryButton: React.FC<Props> = (
  props: Props
): React.ReactElement => {
  return (
    <div>
      <a
        href={`/sbg-webcp-twitter-authorizer/${props.webCampaignKey}`}
        className="button animated-button victoria-one"
      >
        <span>{props.buttonTextLottery}</span>
      </a>
    </div>
  )
}

export default TwitterLotteryButton
