import * as React from 'react'
import moment from 'moment-timezone'

interface CampaignTermProps {
  startAt: number | undefined
  endAt: number | undefined
  termView: boolean
}
/*
 * キャンペーンの「応募期間」をレンダリングするコンポーネントです。
 */
const CampaignTerm: React.FC<CampaignTermProps> = (
  props: CampaignTermProps
): React.ReactElement | null => {
  if (!props.startAt || !props.endAt || !props.termView) {
    return null
  }
  return (
    <div className="container">
      <div className="title">
        <h2>応募期間</h2>
        <span>PERIOD</span>
      </div>
      <div className="col-xs-12">
        <p className="campaign-term">
          {moment(props.startAt)
            .tz('Asia/Tokyo')
            .format('YYYY/MM/DD HH:mm:ss')}{' '}
          〜{' '}
          {moment(props.endAt)
            .tz('Asia/Tokyo')
            .format('YYYY/MM/DD HH:mm:ss')}
        </p>
      </div>
    </div>
  )
}

export default CampaignTerm
