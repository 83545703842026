import * as React from 'react'
interface NotLineFriendshipErrorTextProps {
  isNotLineFriendship: boolean | undefined
}
/*
 * ページロードアイコンをレンダリングするコンポーネントです。
 */
const NotLineFriendshipErrorText: React.FC<NotLineFriendshipErrorTextProps> = (
  props: NotLineFriendshipErrorTextProps
): React.ReactElement => {
  if (!props.isNotLineFriendship) {
    return <div className="campaign-code-error"></div>
  }
  return (
    <div className="campaign-code-error">
      <div>応募条件を満たしていません</div>
      <div>LINE公式アカウントを友だち追加してください</div>
    </div>
  )
}

export default NotLineFriendshipErrorText
