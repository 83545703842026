import * as React from 'react'
/*
 * APIコール時に抽選期間が終了している場合にレンダリングされるコンポーネントです。
 */
export default function CampaignPeriod(): React.FunctionComponentElement<{}> {
  return (
    <article className="container">
      <div className="content">
        <section id="page3">
          <div className="container">
            <div className="title">
              <h2>応募期間は終了しました</h2>
            </div>
            <div className="col-xs-12"></div>
          </div>
        </section>
      </div>
    </article>
  )
}
