import React from 'react'
import sanitizeHtml from 'sanitize-html'

interface LoseResultProps {
  lotteryResult: {
    lostPageTitle?: string
    lostPageText?: string
    lostPageTitlePcFontSize?: string
    lostPageTitleSpFontSize?: string
  }
  device: 'pc' | 'sp'
}
/*
 * 抽選結果画面のハズレの文言をレンダリングするコンポーネントです。
 */
const LoseResult: React.FC<LoseResultProps> = (
  props: LoseResultProps
): React.ReactElement => {
  let titleFontSize
  if (props.lotteryResult.lostPageTitleSpFontSize && props.device === 'sp') {
    titleFontSize = props.lotteryResult.lostPageTitleSpFontSize
  } else if (
    props.lotteryResult.lostPageTitlePcFontSize &&
    props.device === 'pc'
  ) {
    titleFontSize = props.lotteryResult.lostPageTitlePcFontSize
  }
  return (
    <section id="page2" className="hazure">
      <div className="container">
        <div className="ttl">
          <h2>
            {props.lotteryResult.lostPageTitle && (
              <div
                style={titleFontSize ? { fontSize: `${titleFontSize}px` } : {}}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(props.lotteryResult.lostPageTitle)
                }}
              ></div>
            )}
          </h2>
        </div>
        <div className="col-xs-12">
          <div className="hazure-text">
            <p>
              {props.lotteryResult.lostPageText && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(props.lotteryResult.lostPageText)
                  }}
                ></div>
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
export default LoseResult
