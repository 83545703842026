import * as React from 'react'

interface WinTtlProps {
  productName?: string
}
/*
 * 当選結果の「賞品名」をレンダリングするコンポーネントです。
 */
const WinTtl: React.FC<WinTtlProps> = (
  props: WinTtlProps
): React.ReactElement | null => {
  if (!props.productName) {
    return null
  }
  return (
    <div className="present-name">
      <p>{props.productName}</p>
    </div>
  )
}
export default WinTtl
