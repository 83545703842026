import * as React from 'react'
import sanitizeHtml from 'sanitize-html'

interface FooterProps {
  contactInformation: string | undefined
}
/*
 * キャンペーンページのフッター（「キャンペーンに関するお問い合わせ先」）をレンダリングするコンポーネントです。
 */
const Footer: React.FC<FooterProps> = (
  props: FooterProps
): React.ReactElement | null => {
  if (!props.contactInformation) {
    return null
  }
  return (
    <footer>
      <h3>キャンペーンに関するお問い合わせ先</h3>
      <p
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(props.contactInformation)
        }}
      ></p>
    </footer>
  )
}

export default Footer
