import React from 'react'
import { bugsnagClient } from 'src/bugsnag'
import ActionDispatcher from 'src/containers/actionDispatcher'
import { CampaignState } from 'src/modules/campaign'

interface Props {
  onErrorText: (newState: boolean) => void
  campaign: CampaignState
  webCampaignKey: string
  actions: ActionDispatcher
  buttonText: string
}

const LiffLotteryButton: React.FC<Props> = (
  props: Props
): React.ReactElement => {
  const lotteryClickHandler = async (): Promise<void> => {
    if (window.liff) {
      const liff = window.liff
      const decodedIDToken = liff.getDecodedIDToken()
      if (
        !decodedIDToken ||
        !decodedIDToken.exp ||
        new Date().getTime() > decodedIDToken.exp * 1000
      ) {
        // id tokenの有効期限が切れている場合はliffからlogoutする
        liff.logout()
        return window.location.reload()
      }
      if (props.campaign.campaignInfo?.lineFriendship) {
        // 友だち追加必須であれば判定
        await liff
          .getFriendship()
          .then(
            async (data): Promise<void> => {
              if (!data.friendFlag) {
                // 友だち追加されていなければエラーテキスト表示
                return props.onErrorText(true)
              } else {
                // 友だち追加されていれば抽選処理
                await props.actions.asyncLineAuthLottery({
                  campaignKey: props.webCampaignKey,
                  idToken: liff.getIDToken(),
                  accessToken: liff.getAccessToken()
                })
              }
            }
          )
          .catch((e): void => {
            alert(`LIFF Friendship error: ${e.message}`)
            bugsnagClient.notify(e)
          })
      } else {
        //抽選処理
        await props.actions.asyncLineAuthLottery({
          campaignKey: props.webCampaignKey,
          idToken: liff.getIDToken(),
          accessToken: undefined
        })
      }
    }
  }
  return (
    // 抽選用文言のボタンを表示する
    // クリック時にrecaptureのexecute()が呼ばれる
    <span
      className="button"
      onClick={async (): Promise<void> => await lotteryClickHandler()}
    >
      {props.buttonText}
    </span>
  )
}

export default LiffLotteryButton
