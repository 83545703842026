import * as React from 'react'

interface WinThumProps {
  productImageUrl?: string
  productName?: string
}
/*
 * 当選結果の「賞品画像」をレンダリングするコンポーネントです。
 */
const WinThum: React.FC<WinThumProps> = (
  props: WinThumProps
): React.ReactElement | null => {
  if (!props.productName || !props.productImageUrl) {
    return null
  }
  return (
    <div className="present-thum">
      <img
        src={props.productImageUrl}
        className="img-responsive"
        alt={props.productName}
      />
    </div>
  )
}
export default WinThum
