import * as React from 'react'
import { Helmet } from 'react-helmet'

interface PageHeaderProps {
  campaignTitle: string | undefined
}
/*
 * キャンペーンの「キャンペーンタイトル」を<head>内<title>にレンダリングするコンポーネントです。
 */
const PageHeader: React.FC<PageHeaderProps> = (
  props: PageHeaderProps
): React.ReactElement => {
  return (
    <Helmet>
      <title>{props.campaignTitle}</title>
    </Helmet>
  )
}

export default PageHeader
