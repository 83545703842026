import * as React from 'react'
/*
 * 不明なエラーが発生した場合にレンダリングされるコンポーネントです。
 */
export default function FallbackComponent(): React.FunctionComponentElement<{}> {
  return (
    <article className="container">
      <div className="content">
        <section id="page3">
          <div className="container">
            <div className="title">
              <h2>ただいま大変混み合っております</h2>
            </div>
            <div className="col-xs-12">
              誠に恐れ入りますが、時間を置いて再度アクセスいただきますようお願い致します。
            </div>
          </div>
        </section>
      </div>
    </article>
  )
}
