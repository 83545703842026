import * as React from 'react'
import sanitizeHtml from 'sanitize-html'
import TwitterEntryFlow from 'src/components/layouts/twitterEntryFlow'
import LineFriendshipEntryFlow from 'src/components/layouts/lineFriendshipEntryFlow'

interface Page2SectionProps {
  campaignInfo: CampaignInfo
  webCampaignKey: string
  isValidArchived: boolean
}

interface CampaignInfo {
  lineFriendship?: boolean
  entryFlow?: string
  lotteryAuthMethod?: string
  tweetId?: string
  followTwitterUser?: string
  tweetTag?: string
  lineOfficialAccountName?: string
  lineOfficialProfileImageUrl?: string
  lineAddFriendButtonTag?: string
}
/*
 * キャンペーンページのPage2セクション（「応募方法」）をレンダリングするコンポーネントです。
 */
const Page2Section: React.FC<Page2SectionProps> = (
  props: Page2SectionProps
): React.ReactElement | null => {
  if (!props.campaignInfo) {
    return null
  } else {
    if (
      props.campaignInfo.lotteryAuthMethod === 'Twitter' &&
      props.campaignInfo.tweetId &&
      props.campaignInfo.followTwitterUser &&
      props.campaignInfo.tweetTag &&
      !props.isValidArchived
    ) {
      return (
        <TwitterEntryFlow
          tweetId={props.campaignInfo.tweetId}
          followTwitterUser={props.campaignInfo.followTwitterUser}
          tweetTag={props.campaignInfo.tweetTag}
          webCampaignKey={props.webCampaignKey}
        />
      )
    } else if (
      props.campaignInfo.lotteryAuthMethod === 'LINE' &&
      props.campaignInfo.lineFriendship &&
      !props.isValidArchived
    ) {
      return (
        <LineFriendshipEntryFlow
          lineOfficialAccountName={props.campaignInfo.lineOfficialAccountName}
          lineOfficialProfileImageUrl={
            props.campaignInfo.lineOfficialProfileImageUrl
          }
          lineAddFriendButtonTag={props.campaignInfo.lineAddFriendButtonTag}
        />
      )
    } else if (props.campaignInfo.entryFlow) {
      return (
        <section id="page2">
          <div className="container">
            <div className="title">
              <i className="fa fa-twitter" aria-hidden="true"></i>
              <h2>応募方法</h2>
              <span>FLOW</span>
            </div>
            <div className="col-xs-12">
              <div className="step-container">
                <div
                  className="col-md-12"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(props.campaignInfo.entryFlow)
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      )
    } else {
      return null
    }
  }
}

export default Page2Section
