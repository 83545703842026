import * as React from 'react'
import sanitizeHtml from 'sanitize-html'
import TwitterLotteryButton from '../ui/twitterLotteryButton'

interface TwitterEntryFlowProps {
  followTwitterUser: string
  tweetId: string
  tweetTag: string
  webCampaignKey: string
}
/*
 * キャンペーンの「キャンペーン概要」をレンダリングするコンポーネントです。
 */
const TwitterEntryFlow: React.FC<TwitterEntryFlowProps> = (
  props: TwitterEntryFlowProps
): React.ReactElement => {
  //let isLoadwidgets = false
  const [isLoadwidgets, setIsLoadwidgets] = React.useState(false)
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  React.useEffect(() => {
    if (!isLoadwidgets) {
      const s = document.createElement('script')
      s.setAttribute('src', 'https://platform.twitter.com/widgets.js')
      document.body.appendChild(s)
      setIsLoadwidgets(true)
    }
  }, [isLoadwidgets])

  return (
    <section id="page2">
      <div className="container">
        <div className="title">
          <i className="fa fa-twitter" aria-hidden="true"></i>
          <h2>応募方法</h2>
          <span>FLOW</span>
        </div>
        <div className="col-xs-12">
          <div className="step1">
            <div className="col-md-12">
              <h3>Step 1</h3>
              <p>
                <a
                  href={`https://twitter.com/${props.followTwitterUser}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @{props.followTwitterUser}
                </a>
                アカウントをフォローしてください
              </p>
              <a
                href={`https://twitter.com/${props.followTwitterUser}`}
                className="button-twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-twitter" aria-hidden="true"></i>
                {props.followTwitterUser}をフォロー
              </a>
            </div>
          </div>
        </div>
        <div className="col-xs-12">
          <div className="step2">
            <div className="col-md-12">
              <h3>Step 2</h3>
              <p>キャンペーンツイートをリツイート</p>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(props.tweetTag, {
                    allowedAttributes: {
                      '*': ['href', 'class', 'lang', 'dir']
                    }
                  })
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="col-xs-12">
          <div className="step3">
            <div className="col-md-12">
              <h3>Step 3</h3>
              <p>応募ボタンから応募する</p>
              <p>
                <small>※Twitterの連携アプリ認証が入ります。</small>
              </p>
              <br />
              <TwitterLotteryButton
                webCampaignKey={props.webCampaignKey}
                buttonTextLottery={'応募する'}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TwitterEntryFlow
