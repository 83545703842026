import React from 'react'
/*
 * キャンペーン応募期間終了時の文言をレンダリングするコンポーネントです。
 */
const CampaignTermEnd: React.FC<{}> = ({ children }): React.ReactElement => {
  if (children) {
    return (
      <div>
        <h2>応募期間は終了しました</h2>
        <h3>応募結果は下記よりご確認いただけます</h3>
        <br />
        {children}
      </div>
    )
  } else {
    return (
      <div>
        <h2>応募期間は終了しました</h2>
      </div>
    )
  }
}
export default CampaignTermEnd
