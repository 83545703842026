import * as React from 'react'

interface LotteryGimmickProps {
  lotteryGimmick?: number
}
/*
 * キャンペーンの「抽選ギミック」をレンダリングするコンポーネントです。
 */
const LotteryGimmick: React.FC<LotteryGimmickProps> = (
  props: LotteryGimmickProps
): React.ReactElement | null => {
  if (!props.lotteryGimmick) {
    return null
  }
  return (
    <div>
      <div className="lottery-gimmick">
        <img
          src={`/public/image/lottery-gimmick/${props.lotteryGimmick}/gimmick.gif`}
          className="img-responsive"
          alt={'gimmick'}
        />
      </div>
    </div>
  )
}

export default LotteryGimmick
