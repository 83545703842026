import * as React from 'react'

interface WinEntryProps {
  giftContent?: string
}
/*
 * 当選結果の「賞品URL」に遷移可能なボタンをレンダリングするコンポーネントです。
 */
const WinEntry: React.FC<WinEntryProps> = (
  props: WinEntryProps
): React.ReactElement | null => {
  const handleButtonClick = (): void => {
    if (!props.giftContent) {
      return
    }
    if (window.liff) {
      // 「商品を受け取る」ボタンがクリックされた場合
      // window.liff が存在する場合は、liff.openWindow() 関数を実行するなどの処理を追加
      window.liff.openWindow({ url: props.giftContent, external: true })
    } else {
      // 「商品を受け取る」ボタンがクリックされた場合
      // ボタンの表示を変更して、賞品URLに遷移
      window.location.href = props.giftContent
    }
  }
  if (!props.giftContent) {
    return null
  }
  return (
    <div>
      <p>
        下の｢商品を受け取る｣をクリックして､
        <br />
        商品をお受け取りください。
        <br />
        ※この画面を提示しても商品お受け取りできません
      </p>
      <div className="present-entry">
        <button
          onClick={handleButtonClick}
          className="button animated-button victoria-one"
        >
          <span>商品を受け取る</span>
        </button>
      </div>
    </div>
  )
}
export default WinEntry
