import * as React from 'react'

/*
 * ページロードアイコンをレンダリングするコンポーネントです。
 */
const PageLoadingIcon: React.FC<{}> = (): React.ReactElement => {
  return (
    <div className="spinner-border-parent">
      <div className="spinner-border"></div>
      <div>Now Loading</div>
    </div>
  )
}

export default PageLoadingIcon
