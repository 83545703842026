import * as React from 'react'
import { TopPageState } from 'src/modules/toppage'
import { LotteryState } from 'src/modules/lottery'
import { CampaignState } from 'src/modules/campaign'
import Header from 'src/components/layouts/header'
import PageHeader from 'src/components/layouts/pageHeader'
import Footer from 'src/components/layouts/footer'
import LotteryResult from 'src/components/layouts/lottery/lotteryResult'
import PageLoadingIcon from 'src/components/ui/pageLoadingIcon'
import InvalidUrl from 'src/components/pages/invalidUrl'
import { Cookies } from 'react-cookie'
interface Props {
  value: TopPageState
  lottery: LotteryState
  campaign: CampaignState
  actions: LotteryActionDispatcher
  campaignCode?: string
  campaignKey?: string
  router: RouterLocationState
}

interface LotteryActionDispatcher {
  asyncGetCampaign(campaignKey: string | undefined): Promise<void>
  setLotteryResult(lotteryResult: string | {}): void
  push(location: string): void
}

interface RouterLocationState {
  location: {
    state: string | {}
  }
}

/*
 * APIで抽選後に遷移する画面でレンダリングされるコンポーネントです。
 * router.location.stateに抽選結果のhash値がある場合、抽選結果画面がレンダリングされます。
 */
export class Lottery extends React.Component<Props, {}> {
  async componentDidMount(): Promise<void> {
    const {
      campaignKey: paramsCampaignKey,
      campaignCode: paramsCampaignCode,
      actions,
      router
    } = this.props
    // 遷移前のstateの値を抽選結果stateにセット
    await actions.setLotteryResult(router.location.state)
    const { lotteryResult } = this.props.lottery
    if (lotteryResult === undefined) {
      //location.stateに応募結果が存在するか
      if (paramsCampaignKey && paramsCampaignCode) {
        return actions.push(`/${paramsCampaignKey}/${paramsCampaignCode}`)
      } else if (paramsCampaignKey && !paramsCampaignCode) {
        return actions.push(`/${paramsCampaignKey}`)
      } else {
        return actions.push('/routing_failure')
      }
    } else {
      await actions.asyncGetCampaign(paramsCampaignKey)
    }
  }

  render(): React.ReactElement {
    if (this.props.value.loadingGetCampaignInfoCount !== 0) {
      return <PageLoadingIcon />
    }
    const lotteryResult = this.props.lottery.lotteryResult
    const campaignInfo = this.props.campaign.campaignInfo
    if (!campaignInfo || !lotteryResult) {
      return <InvalidUrl />
    }
    //応募方法がコード方式、Twitterの場合のみcookieに保存
    if (
      campaignInfo.lotteryAuthMethod === 'CampaignCodeForm' ||
      campaignInfo.lotteryAuthMethod === 'CampaignCodeUrl' ||
      campaignInfo.lotteryAuthMethod === 'Twitter'
    ) {
      const cookie = new Cookies()
      const tD = new Date(campaignInfo.endAt)
      tD.setTime(tD.getTime() + 60 * 60 * 1000 * +9)
      let cpPathName = `/${this.props.campaignKey}`
      if (this.props.campaignCode) {
        cpPathName = `/${this.props.campaignKey}/${this.props.campaignCode}`
      }
      cookie.set(cpPathName, true, {
        path: cpPathName,
        secure: true,
        sameSite: true,
        expires: tD
      })
    }
    return (
      <div>
        <PageHeader campaignTitle={campaignInfo.campaignTitle} />
        <Header campaignLogoImageUrl={campaignInfo.campaignLogoImageUrl} />
        <article>
          <div className="content">
            <LotteryResult lotteryResult={lotteryResult} />
          </div>
          <Footer contactInformation={campaignInfo.contactInformation} />
        </article>
      </div>
    )
  }
}
