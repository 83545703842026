import * as React from 'react'
import sanitizeHtml from 'sanitize-html'

interface WinNoticeProps {
  notice?: string
}
/*
 * 当選結果の「注意事項」をレンダリングするコンポーネントです。
 */
const WinNotice: React.FC<WinNoticeProps> = (
  props: WinNoticeProps
): React.ReactElement | null => {
  if (!props.notice) {
    return null
  }
  return (
    <section id="page3">
      <div className="container">
        <div className="title">
          <h2>注意事項</h2>
          <span>TERMS</span>
        </div>
        <div className="col-xs-12">
          <p
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(props.notice, {
                allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img'])
              })
            }}
          ></p>
        </div>
      </div>
    </section>
  )
}
export default WinNotice
