import React from 'react'

/*
 * キャンペーンページのPage1セクションをレンダリングするコンポーネントです。
 */
const Page1Section: React.FC<{}> = ({ children }): React.ReactElement => {
  return (
    <section id="page1">
      <div className="col-xs-12">{children}</div>
    </section>
  )
}

export default Page1Section
