import * as React from 'react'
/*
 * ルーティングに失敗した際にレンダリングされるコンポーネントです。
 */
export default function RoutingFailure(): React.FunctionComponentElement<{}> {
  return (
    <article>
      <div className="content">
        <section id="page3">
          <div className="container">
            <div className="title">
              <h2>正常にページ遷移できませんでした</h2>
            </div>
            <div className="col-xs-12">
              恐れ入りますが最初のURLからアクセスし直してください。
            </div>
          </div>
        </section>
      </div>
    </article>
  )
}
