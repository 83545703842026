import * as React from 'react'
/*
 * APIコール時に不正なセッションと判定された場合にレンダリングされるコンポーネントです。
 */
export default function InvalidSession(): React.FunctionComponentElement<{}> {
  return (
    <article>
      <div className="content">
        <section id="page3">
          <div className="container">
            <div className="title">
              <h2>ログイン認証に失敗しました</h2>
            </div>
            <div className="col-xs-12">
              本メッセージが表示された理由として、下記のいずれかが考えられます。
              <br />
              <br />
              ・同じ端末、または異なる端末に関わらず別の画面からログインがなされた。
              <br />
              ・直前の操作から10分以上操作がなかったためログイン認証が自動的に無効となった。
              <br />
              <br />
              引き続きキャンペーンに応募いただくには
              <br />
              再度最初からログインしていただく必要がございます。
            </div>
          </div>
        </section>
      </div>
    </article>
  )
}
