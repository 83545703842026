import moment from 'moment'

class Validation {
  public static CampaignEndIsAfter = (
    endTimeUnix: number | undefined
  ): boolean => {
    if (!endTimeUnix) {
      return false
    }
    const nowTime = moment().locale('ja')
    const endTime = moment(endTimeUnix).locale('ja')
    return nowTime.isAfter(endTime)
  }
  public static CampaignStartIsBefore = (
    startTimeUnix: number | undefined
  ): boolean => {
    if (!startTimeUnix) {
      return false
    }
    const nowTime = moment().locale('ja')
    const startTime = moment(startTimeUnix).locale('ja')
    return nowTime.isBefore(startTime)
  }
  public static isValidHashCodeLength = (code: string | undefined): boolean => {
    if (code === undefined) {
      return false
    }
    const isUUID = (value: string): boolean => {
      const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
      return uuidPattern.test(value)
    }
    return code.length === 8 || code.length === 10 || isUUID(code)
      ? true
      : false
  }
}

export default Validation
